.client_info {
  font-family: Gm;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #000000;
}

.searchInputNew {
  width: 374px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  height: 44px;
  padding: 7px 10px 10px 40px;
  align-items: center;
  border-radius: 8px;
}

.searchInputNew::placeholder {
  /* //styleName: Text md/Normal; */
  font-family: Gm;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.filter_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 36px;
  padding: 8px;
  border-radius: 2px;
  gap: 4px;
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}

.addColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 119px;
  height: 36px;
  width: 119px;
  border-radius: 2px;
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  color: #151414;
}

.allTable {
  overflow-x: auto;
  overflow-y: auto;
  height: 620px;
}

.allTable::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.allTable::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.02);
  box-shadow: -0.5px 0px 0px 0px rgba(0, 0, 0, 0.07) inset,
    -1px 0px 0px 0px rgba(0, 0, 0, 0.02) inset,
    1px 0px 0px 0px rgba(0, 0, 0, 0.02) inset,
    0.5px 0px 0px 0px rgba(0, 0, 0, 0.07) inset;
}

.allTable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.51);
  border-radius: 4px;
  height: 200px;
}

.edit_details {
  width: 82px;
  height: 42px;
  padding: 9px 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 6px;
  background: var(--Primary-PR500, #4094f7);
  color: #f6f8f9;
  font-family: "Gm";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.07px;
}

.actionList {
  position: absolute;
  top: 120px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09) !important;
  border: 1px solid #dde2e4;
  border-radius: 2px;
  width: 197px;
  right: 24px;
}

.menuItem {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: Gm;
  font-weight: 500;
  cursor: pointer !important;
  height: 40px;
  text-decoration: none;
  background: #fff;
  font-family: "Gm";
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px;
}

.user_heading {
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #969696;
}

.user_data {
  font-family: Gm;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: #221e1f;
}

.discard {
  width: 81px;
  height: 42px;
  border-radius: 6px;
  border: 1px solid #dde2e4;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #344054;
}

.save {
  width: 81px;
  height: 42px;
  border-radius: 6px;
  background: #fbc944;
  border-radius: 6px;
  border: 1px solid #dde2e4;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #344054;
}

.user_input {
  outline: none;
  width: 168px;
  height: 42px;
  border-radius: 2px;
  background: #f5f8fa;
  font-family: Gr;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.haltReason {
  width: 100%;
  height: 100px;
  border-radius: 2px;
  border: 1px solid #cfcfcf;
  margin-top: 10px;
  outline: none;
  padding: 14px 0px 0px 15px;
}

.haltReason::placeholder {
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.submit_reason {
  width: 100%;
  height: 44px;
  border-radius: 2px;
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #fcc200 104.3%);
  font-family: Gm;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
}

.edit_allocation {
  border: 1.333px solid var(--Gray-300, #d0d5dd);
  color: #1364c2;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 185.714% */
  padding: 5px 10px;
}

.allo_heading {
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  color: #969696;
}

.allo_content {
  font-family: Gm;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #221e1f;
}

.allo_discard {
  width: 82px;
  height: 25px;
  border: 0.5px solid #dde2e4;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #344054;
}

.allo_save {
  width: 66px;
  height: 25px;
  border-radius: 1px;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #344054;
}

.salesInfo_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-rows: repeat(5, 1fr); */
  grid-column-gap: 80px;
  grid-row-gap: 20px;
}

.project_Info {
  width: 137px;
  height: 42px;
  border-radius: 2px;
  background: #f5f8fa;
  outline: none;
  padding: 12px;
  font-family: Gm;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #221e1f;
}

.plot_info {
  outline: none;
  width: 68px;
  height: 42px;
  border-radius: 2px;
  background: #f5f8fa;
  padding: 12px;
  font-family: Gm;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #221e1f;
}

.ft {
  display: flex;
  width: 35px;
  height: 42px;
  justify-content: center;
  background-color: #fbc944;
  align-items: center;
  font-size: 14px;
  font-family: Gm;
  font-weight: 500;
  color: black;
}

.gated {
  width: 300px;
  height: 42px;
  border-radius: 2px;
  background: #f5f8fa;
  outline: none;
  padding: 12px;
  font-family: Gm;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #221e1f;
}

.location_input {
  height: 67px;
  border-radius: 2px;
  border: 0.96px solid #d0d0d0;
  background: #f5f8fa;
  padding: 12px;
  outline: none;
  font-family: Gm;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #221e1f;
}

.uploadDoc {
  width: 84px;
  height: 36px;
  border-radius: 2px;
  background: #fbc944;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: #344054;
}

.view {
  cursor: pointer;
  width: 50px;
  height: 34px;
  border: 1px solid #d0d5dd;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: Gm;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #344054;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dwnl {
  width: 81px;
  height: 34px;
  border: 1px solid #d0d5dd;
  border-left: none;
  font-family: Gm;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #344054;
}

.delete {
  width: 61px;
  height: 34px;
  border: 1px solid #d0d5dd;
  border-left: none;
  font-family: Gm;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #ff0000;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.doc_input {
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  padding: 16px;
  outline: none;
}

.doc_input::placeholder {
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  color: #969696;
}

.doc_field {
  width: 100%;
  height: 250px;
  border: 2px dashed #e6e6e6;
  border-radius: 2px;
}

.browsefile {
  width: 108px;
  height: 35px;
  border-radius: 3px;
  color: #fffcf3;
  border: 1px solid #fcc200;
  font-family: Gm;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #0e0e0e;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadDoc_btn {
  width: 100%;
  height: 45px;
  border-radius: 2px;
  background: linear-gradient(93.17deg, #ffe185 -7.49%, #f7ba00 100%);
}

.daterange .rs-input-group.rs-input-group-inside {
  height: 44px !important;
}

.daterange .rs-picker-error .rs-input-group {
  border-color: #cfcfcf !important;
}

.resetBtn {
  width: 100%;
  height: 42px;
  border-radius: 2px;
  background: #f2f2f2;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: #000000;
}

.applyBtn {
  width: 100%;
  height: 42px;
  border-radius: 2px;
  background: linear-gradient(93.17deg, #ffe185 -7.49%, #f7ba00 100%);
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: #000000;
}
.ProjectStages_title {
  color: #000;
  font-family: gsb;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.InvoiceDate {
  color: var(--Dark-Gray, #969696);
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.InvoiceDatesub {
  color: var(--Black, var(--Body-Text-Primary, #221e1f));
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.AddInvoice__popup {
  color: var(--Black, #0e0e0e);
  font-family: gsb;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.AddInvoice__popup_input {
  margin-top: 10px;
  border-radius: 2px;
  border: 1px solid #cfcfcf;
  background: var(--BG-Primary, #fff);
  padding: 8px;
  outline: none;
  width: 100%;
}

.AddInvoice__popup_input__title {
  color: var(--Black, #0e0e0e);
  font-family: gsb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dropzone_img {
  text-align: center;
  padding: 20px;
  color: #bdbdbd;
  /* margin-bottom: 20px; */
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fffdf8;
  border: 1px dashed #fcc200;
  border-radius: 2px;
}
.drag_drop_txt {
  font-family: "Gsb";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  color: #151414;
}

.browse_btn,
.upload_btn,
.browse_cancel_btn {
  box-sizing: border-box;
  padding: 10px;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #151414;
  border-radius: 2px;
}

.filename {
  color: #000;
  text-overflow: ellipsis;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.989px; /* 164.204% */
}
.viewwww {
  color: var(--Dark-Gray, #7d7d7d);
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.Delete {
  color: #f05f5f;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.editButton {
  color: #1364c2;
  font-family: "gsb";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 200% */
  border: 1.333px solid var(--Gray-300, #d0d5dd);
  padding: 3px 15px;
}

.titleNameNote {
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.titleNameNotesub {
  color: var(--Body-Text-Tertiary, #5a5959);
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.titleNameNotesubone {
  color: var(--Black, #0e0e0e);
  font-family: GM;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.557px; /* 189.691% */
}

.activitytitle {
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.activitysubtitle {
  color: #5a5959;

  font-family: gm;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.DesignDiscussion_title {
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Yettostart {
  background: #d3ecff;
  padding: 7px;
  color: #0075ff;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.UpdatedBy {
  color: #5a5959;
  font-family: gm;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Activate {
  padding: 5px 10px;
  border: 1.333px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: #00a106;
  font-family: gsb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 200% */
}

.ProjectStages__Card__Title {
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProjectStages__Yettostart {
  padding: 7px;
  background: #d3ecff;
  color: #0075ff;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProjectStages__UpdatedBy {
  color: #5a5959;
  font-family: "gm";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ProjectStages__Activate {
  padding: 5px 10px;
  color: #00a106;
  font-family: gsb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 200% */
  border: 1.333px solid var(--Gray-300, #d0d5dd);
  background: #fff;
}
.ProjectStagesPopFill__Stage__Tittle {
  color: var(--Black, #0e0e0e);
  font-family: gsb;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProjectStages___Stage__input {
  border-radius: 2px;
  border: 1px solid #cfcfcf;
  background: #fff;
  padding: 10px;
}
.Stage__FormTittle {
  color: var(--Black, #0e0e0e);
  font-family: gsb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProjectStages__activateButton {
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px;
  width: 100%;
  border-radius: 2px;
  background: linear-gradient(92deg, #ffe185 -0.46%, #f6ba00 104.3%);
}

.ProjectStages__InProgress {
  background: rgba(241, 188, 0, 0.15);
  color: #d49900;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProjectStages__More {
  text-decoration: underline;
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 185.714% */
  text-decoration-line: underline;
}
.StageAccordionDetails__title {
  color: var(--Dark-Gray, #969696);
  font-family: gsb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.StageAccordionDetails__subtitle {
  color: var(--Black, #221e1f);
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Single__StageName {
  color: #000;
  font-family: gsb;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 400px;
}

.input {
  width: 100%;
  height: 44px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #fff;
  color: #0d0c22;
  transition: 0.3s ease;
  border: 1px solid var(--Gray-300, #d0d5dd);
}

.input::placeholder {
  color: #9e9ea7;
}

/* .input:focus,
input:hover {
  outline: none;
  border-color: rgba(234, 226, 183, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(234 226 183 / 10%);
} */

.icon {
  position: absolute;
  left: 2rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}
.StageAccordionDetails__Ssubtitle {
  color: #4d4d4d;
  padding: 5px;
  width: fit-content;
  font-family: gsb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: rgba(0, 0, 0, 0.07);
}
.dropzone {
  margin-top: 10px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border: 3px dashed #969696;
  background: #fffcf3;
  color: #bdbdbd;
  margin-bottom: 20px;

  height: 250px;
  display: flex;
  align-items: center;
}
.vendor_bulk_upload_text {
  font-family: gsb;
  color: #000;
}
.browse_bulk_vendor {
  padding: 12px;
  font-family: gsb;
  border: 1px solid #fcc216;
  box-sizing: border-box;
  border-radius: 3px;
  background: #fffcf3;
}
.StageAccordionDetails__Agreement {
  color: #000;
  text-overflow: ellipsis;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.989px; /* 164.204% */
}
.StageAccordionDetails__AgreementV {
  color: var(--Dark-Gray, #7d7d7d);
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.MUIstayle__button {
  box-shadow: none;
  border-radius: 2px;
  background: linear-gradient(90deg, #ffd661 0%, #fabe2a 103.54%);
  text-transform: none;
  padding: 10px;
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.Stage__SubFormTittle {
  color: var(--Black, #221e1f);
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ProjectStages__InProgresstask {
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.stage__status {
  color: #000;
  font-family: gsb;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.note__title {
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.note__subtitle {
  color: var(--Black, #0e0e0e);
  font-family: gm;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.557px; /* 189.691% */
}

.ConstructionDetails_tittle {
  color: #000;
  font-family: gsb;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.DaysLeft {
  color: #344054;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.rs-input {
  padding: 10px 11px !important;
}

.backbutton {
  color: #000;
  text-align: center;
  font-family: "gsb";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
