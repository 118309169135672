.manageuser_info {
  font-family: Gm;
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  color: #000000;
}

.searchuser {
  width: 400px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  height: 38px;
  padding: 10px 10px 10px 40px;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #667085;
}

.filter_btn {
  width: 68px;
  height: 36px;
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: #344054;
  border-radius: 2px;
}

.adduser_btn {
  width: 99px;
  height: 38px;
  border-radius: 1px;
  background: #ffffff;
  font-family: Gr;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #221e1f;
}

.del_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 38px;
  border-radius: 2px;
  background-color: #ffffff;
  cursor: pointer;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  /* // Box. */
  & + label:before {
    content: "";
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border: 1px solid #f7b314;
    border-radius: 2px;
  }

  /* // Box checked */
  &:checked + label:before {
    background: #fcc200;
  }

  /* // Checkmark. Could be replaced with an image */
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

/*pagintaion css antd  */
.dealsPagination .ant-pagination-prev,
.dealsPagination .ant-pagination-next {
  border-radius: 4px;
  border: 1px solid #d8dae5;
  height: auto;
  background: #fff;
}

.dealsPagination .ant-pagination-item {
  border-radius: 4px;
  border: 1px solid #d8dae5;
  background: #fff;
  outline: none;
  color: #051b44;
  font-family: Gm;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
}
.dealsPagination .ant-pagination-item a {
  color: #051b44;
  font-family: Gm;
  font-size: 14px;
  font-weight: 400;
}

.dealsPagination .ant-pagination-item-active {
  outline: none;
  box-shadow: none;
  background: #f7b314;
  border-radius: 4px;
  border-color: none;
  height: auto;
}
.dealsPagination .ant-pagination-item-active:hover {
  outline: none;
  box-shadow: none;
  background: #f7b314;
  border-radius: 4px;
  border: 1px solid #d8dae5;
  height: auto;
  color: white;
}

.dealsPagination .ant-pagination-item-active a:hover {
  outline: none;
  box-shadow: none;
  background: #f7b314;
  border-radius: 4px;
  /* border: 1px solid #d8dae5; */
  color: white;
  height: auto;
}

.dealsPagination .ant-pagination-item-active a {
  color: #fff;
  font-family: Gm;
  font-size: 14px;
  font-weight: 400;
  transition: color 0s;
}
.dealsPagination .ant-pagination-options {
  float: right;
}

.dealsPagination .ant-select-selector {
  height: 32px;
  padding: 0px 8px;
  outline: none;
  width: auto;
  height: auto;
  border-radius: 4px;
  border: 1px solid #d8dae5;
  background: #fff;
  box-shadow: none;
  color: #051b44;
  font-family: Gm;
  font-size: 14px;
  font-weight: 400;
}
.dealsPagination .ant-select-selector:hover {
  outline: none;
  box-shadow: none;
  border: 1px solid #d8dae5;
}

.myan {
  height: 32px;
  padding: 0px 8px;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.manageUser_table {
  overflow-x: auto;
  overflow-y: auto;
  height: 510px;
}

.manageUser_table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.manageUser_table::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.02);
  box-shadow: -0.5px 0px 0px 0px rgba(0, 0, 0, 0.07) inset,
    -1px 0px 0px 0px rgba(0, 0, 0, 0.02) inset,
    1px 0px 0px 0px rgba(0, 0, 0, 0.02) inset,
    0.5px 0px 0px 0px rgba(0, 0, 0, 0.07) inset;
}

.manageUser_table::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.51);
  border-radius: 4px;
  height: 200px;
}

.addUsr_input {
  width: 100%;
  height: 44px;
  border-radius: 2px;
  border: 1px;
  border: 1px solid #cfcfcf;
  outline: none;
  padding: 10px;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #969696;
}

.small_input {
  width: 203px;
  height: 44px;
  border-radius: 2px;
  border: 1px;
  border: 1px solid #cfcfcf;
  outline: none;
  padding: 10px;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #969696;
}

.num91 {
  font-family: "Gm";
  font-size: 13px;
  line-height: 15px;
  color: #0e0e0e;
  background: #ffdb63;
  border-radius: 2px 0px 0px 2px;
  /* padding: 12px; */
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numInput {
  width: 166px;
  height: 44px;
  border-radius: 2px;
  border: 1px;
  border: 1px solid #cfcfcf;
  outline: none;
  padding: 10px;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #969696;
}

.pwdInput {
  width: 170px;
  padding: 10px 30px 10px 10px;
  font-size: 14px;
  font-family: "Gm";
  outline: none;
  /* width: 100%; */
  /* width: 70%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 44px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top: 1px solid #cfcfcf;
  border-left: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
}

.userpassword_eye {
  /* color: #bbbbbb; */
  cursor: pointer;
}

.hide {
  font-size: 14px;
  height: 44px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  background: #fff;
  display: flex;
  align-items: center;
}

.add_btn {
  width: 100%;
  padding: 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  font-family: "Gm";
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 2px;
  border: none;
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
}

.required_message {
  color: red;
  margin-top: 5px;
}

.deleteText {
  font-family: "Gsb";
  font-style: normal;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #000;
}

.cancelDelUser {
  width: 171px;
  height: 42px;
  border-radius: 2px;
  background-color: #f2f2f2;
  font-family: Gsb;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  color: #000000;
}

.delUser {
  width: 171px;
  height: 42px;
  border-radius: 2px;
  background-color: #fe3e3e;
  font-family: Gsb;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  outline: none;
}

.usersPath {
  /* color: var(--Dark-Gray, #969696; */
  font-family: Gm;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user_title {
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15.77px;
  color: #969696;
  padding-bottom: 4px;
}

.user_value {
  font-family: "Gsb";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #221e1f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userInput2 {
  font-family: "Gm";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  padding: 10px;
  outline: none;
  border: none;
  background: #fff9e5;
  width: 160px;
  height: 38px;
}

.plus91 {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  font-family: "Gm";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  background: #fcc200;
  border-radius: 1px 0px 0px 1px;
  height: 38px;
}

.numberinput {
  font-family: "Gm";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  padding: 10px;
  outline: none;
  border: none;
  background: #f4f4f4;
  width: 115px;
  height: 38px;
}

.saveForm {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;
  background: none;
  color: #fcc314;
}

.userInput {
  font-family: "Gm";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  padding: 10px;
  outline: none;
  border: none;
  background: #f4f4f4;
  width: 160px;
  height: 38px;
}

.action_btn {
  outline: none;
  width: 102px;
  height: 42px;
  padding: 10px;
  border-radius: 6px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #dde2e4, #dde2e4);
  border: 1px solid #dde2e4;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Gm;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #252c32;
}

.menulist {
  position: absolute;
  top: 105px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09) !important;
  border-radius: 2px;
  width: 170px;
  right: 45px;
}

.menu_item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer !important;
  height: 40px;
  text-decoration: none;
  background: #fff;
  font-family: "Gm";
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding: 8px 16px;
}

.deactivateContent {
  font-family: Gr;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.deactivate_btn {
  border-radius: 2px;
  background: #fe3e3e;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  font-family: "Gsb";
  line-height: normal;
  width: 167px;
  height: 43px;
}

.cancelbtn {
  color: #221e1f;
  background: #f4f4f4;
  border-radius: 2px;
  font-weight: 600;
  font-family: "Gsb";
  padding: 10px;
  border: none;
  font-size: 13px;
  line-height: 15px;
  width: 167px;
  height: 43px;
}

.filter_title {
  font-family: "Gsb";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #151414;
}

.filter_inputfields {
  font-family: "Gm";
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #0e0e0e;
  padding: 5px 14px 5px 14px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  height: 40px;
}

.resetFitler {
  background: #f2f2f2;
  width: 100%;
  border-radius: 3px;
  font-family: Gm;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000;
  border: none;
  outline: none;
  padding: 14px;
}

.applyFilter {
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
  width: 100%;
  border-radius: 3px;
  font-family: Gm;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000;
  border: none;
  outline: none;
  padding: 14px;
}

.sub_heading {
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0e0e;
}

.rs-picker-popup {
  z-index: 50 !important;
}

.focus\:ring-2:focus {
  box-shadow: none !important;
}

.manageUserDateRangePicker .rs-picker-error .rs-input-group {
  border-color: #cfcfcf !important;
}

.activeUser {
  width: 78px;
  height: 29px;
  border-radius: 1px;
  background: #f1fff4;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.98px;
  color: #3add5e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inactiveUser {
  width: 78px;
  height: 29px;
  border-radius: 1px;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.98px;
  background: #ffecec;
  color: #fe3e3e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manageUser_filterBtn {
  display: flex;
  width: 81px;
  padding: 11px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: var(--BG-Primary, #fff);
  color: var(--Primary-Black, #151414);
  font-family: Gm;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
