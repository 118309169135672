@font-face {
  font-family: "Gm";
  src: local("Gm"),
    /* url("../../Fonts/Gilroy\ Font/Gilroy-Medium.ttf") format("truetype"); */url("../src//Fonts//Gilroy\ Font/Gilroy-Medium.ttf")
      format("truetype");
}

@font-face {
  font-family: "Gr";
  src: local("Gr"),
    /* url("../../Fonts/Gilroy\ Font/Gilroy-Regular.ttf") format("truetype"); */url("../src//Fonts/Gilroy\ Font/Gilroy-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    /* url("../../Fonts/Gilroy\ Font/Gilroy-SemiBold.ttf") format("truetype"); */url("../src/Fonts/Gilroy\ Font/Gilroy-SemiBold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Gb";
  src: local("Gb"),
    /* url("../../Fonts/Gilroy\ Font/Gilroy-Bold.ttf") format("truetype"); */url("../src/Fonts/Gilroy\ Font/Gilroy-Bold.ttf")
      format("truetype");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rs-picker-error > .rs-input-group {
  border-color: "white" !important;
}
.rs-picker-error > .rs-input-group {
  border-color: "white" !important;
}

/* searchbar */
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 400px;
}

.input {
  width: 400px;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border-radius: 2px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  color: var(--Gray-500, var(--Body-Text-Tertiary, #667085));
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease;
}

.input::placeholder {
  color: #9e9ea7;
}

.input:focus,
input:hover {
  outline: none;
  /* border-color: rgba(0, 48, 73, 0.4); */
  background-color: #fff;
  /* box-shadow: 0 0 0 4px rgb(0 48 73 / 10%); */
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}

.AddInvoice__popup_inputFilter {
  color: var(--Gray-500, var(--Body-Text-Tertiary, #667085));
  font-family: gm;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  border: 1px solid var(--Gray-300, #d0d5dd);
  outline: none;
  padding: 7px;
}

.AccordionSummary__title {
  color: #000;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AccordionSummary__status {
  background: rgba(241, 188, 0, 0.15);
  padding: 5px;
  color: #d59900;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.AccordionSummary__2title {
  color: #5a5959;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.AccordionSummary__3title {
  color: #5a5959;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.view__more {
  color: #000;
  font-family: gsb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 200% */
  text-decoration-line: underline;
}

.AccordionDetails_title {
  color: var(--Dark-Gray, #969696);
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.AccordionDetails_subtitle {
  color: var(--Black, var(--Body-Text-Primary, #221e1f));
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Attendees_box {
  color: #4d4d4d;
  font-family: gsb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: rgba(0, 0, 0, 0.07);
  width: fit-content;
  padding: 5px;
}

.button__mty {
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 185.714% */
  padding: 5px 15px;
  border: 1.333px solid var(--Gray-300, #d0d5dd);
  width: fit-content;
}

.rs-picker-error > .rs-input-group {
  background-color: white !important;
  border-radius: 5px !important;
  padding: 3px;
}

.rs-picker-error > .rs-input-group:focus-within {
  outline: none !important;
  border-radius: 5px !important;
}
.rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: #cfcfcf !important;
  border-radius: 5px !important;
}
.rs-input-group .rs-input-group-inside {
  border-color: #cfcfcf !important;
  border-radius: 5px !important;
}

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  outline: 3px solid white;
  border-color: #cfcfcf;
  border-radius: 5px;
}

.rs-picker-error > .rs-input-group {
  border-color: #cfcfcf !important;
  border-radius: 5px !important;
}
