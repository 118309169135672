.Login_bg {
  background-color: #f4f4f4;
  mix-blend-mode: normal;
}

.login {
  height: 100vh;
  border: none;
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login_txt {
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  font-size: 36px;
  color: #000;
}

.label_txt {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}

.login_input_fileds {
  border: 1px solid #8692a6;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Gr;
  font-size: 16px;
  line-height: 20px;
  color: #555c62;
  padding: 15px;
  width: 100%;
  outline: none;
  height: 50px;
  font-family: Gm;
  font-weight: 400;
}

.label_txt {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}

.password_wrapper {
  width: 100%;
  border: 1px solid #8692a6;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* c */
}

.password_field:focus {
  outline: none;
}

.password_field {
  border: none;
  width: 94%;
  /* background-color: #f6f6f6;
    height: 40px; */
  font-family: Gm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 15px;
  color: #555c62;
  border-radius: 5px;
}

.password_eye {
  color: #bbbbbb;
  cursor: pointer;
}

.submit_btn {
  border: none;
  width: 100%;
  height: 50px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-family: Gm;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #221e1f;
  outline: none;
}
