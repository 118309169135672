.sidebarnav {
  height: 100%;
  width: 240px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-y: scroll;
}

.sidebarnav::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.side_menu_dashboardbtn1 {
  color: #909090;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
}
.side_menu_dashboardbtn1:hover {
  color: #151414;
  text-decoration: none;
}
.side_menu_dashboardbtn1:hover {
  background: linear-gradient(90deg, #ffdb6c -9.01%, #f7b314 127.45%);
}
.side_menu_dashboardbtn1:hover svg > path {
  fill: black;
}
.side_menu_dashboardbtn1:hover svg > g > path {
  fill: black;
}

.side_menu_dashboardbtn1 {
  background: #f4f4f4;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 2px;
  color: #000000;
  font-family: Gm;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  height: 49px;
}

.make_side_menu_yellow {
  background: linear-gradient(90deg, #ffdb6c -9.01%, #f7b314 127.45%);
}
.make_side_menu_yellow svg > path {
  fill: black;
}
.make_side_menu_yellow svg > g > path {
  fill: black;
}
.make_side_menu_yellow span {
  color: black;
}

.profile_details span {
  font-family: "Gm";
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 4.5px;
}

.profile_details p {
  font-family: "Gm";
  font-size: 13px;
  font-weight: 400;
  color: #7e7e7e;
}

.confirm_logout {
  width: 172px;
  height: 42px;
  border-radius: 2px;
  background: #fe3e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
}

.cancel_logout {
  width: 172px;
  height: 42px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #000000;
  cursor: pointer;
}
