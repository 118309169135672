.tabcolor .MuiTab-root.MuiButtonBase-root {
  border-top-right-radius: 8px;
  border-bottom: none !important;
}

.project_Heading {
  font-family: Gsb;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #000000;
}

.addstatus_btn {
  height: 36px;
  padding: 8px;
  border-radius: 2px;
  background: #fbc944;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}

.content_title {
  font-family: Gm;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #000000;
}

.edit_stuts {
  width: 66px;
  height: 32px;
  background: #ffffff;
  border: 1.33px solid #d0d5dd;
  border-radius: 5px;
  box-shadow: 0px 1.3328801393508911px 2.6657602787017822px 0px #1018280d;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  color: #344054;
}

.delete_status {
  width: 82px;
  height: 32px;
  border-radius: 5px;
  background: #ffffff;
  border: 1.33px solid #d0d5dd;
  border-radius: 5px;
  box-shadow: 0px 1.3328801393508911px 2.6657602787017822px 0px #1018280d;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  color: #fe3e3e;
}

.add_input {
  height: 44px;
  border-radius: 2px;
  border: 1px solid #8692a6;
  color: #7d7d7d;
  outline: none;
  padding: 0px 8px;
}

.input_btn {
  height: 45px;
  border-radius: 2px;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  font-family: Gr;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.c_text {
  font-family: Gm;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #ffffff;
}

.edit_testimonial {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 21px;
  width: 66px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  color: #0062ff;
  line-height: 26px;
  text-align: left;

  margin-right: 0px;
  box-shadow: 0px 1.33px 2.67px 0px #1018280d;
}

.remove_testimonial {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 21px;
  width: 90px;
  height: 32px;
  background: #ffffff;
  border: 1.33288px solid #d0d5dd;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #fe3e3e;
}

.dnd_top {
  background: linear-gradient(95.67deg, #626262 3.24%, #151414 107.53%);
}

.Carouselsequence {
  font-size: 14px;
  font-weight: 500;
  font-family: "Gm";
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 120px;
  height: 36px;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #d0d5dd;
  color: #344054;
  outline: none;
}

.dnd_save {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 49px;
  height: 36px;
  background: #fbc944;
  border-radius: 2px;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  outline: none;
}

.checkbox-wrapper input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-wrapper label {
  position: relative;
  cursor: pointer;
  display: flex;
}

.checkbox-wrapper label:before {
  content: "";
  appearance: none;
  background-color: transparent;
  border: 2px solid #f4ba06;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkbox-wrapper input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1.8px;
  left: 5.3px;
  width: 6px;
  height: 11px;
  border: solid #f4ba06;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.addstage_btn {
  margin-top: 24px;
  width: 100%;
  height: 45px;
  border-radius: 2px;
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #000000;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
}

.stageTop {
  box-shadow: 0px 1px 8.7px 0px #00000026;
}
