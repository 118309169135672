.searchdocument {
  width: 329px;
  border-radius: 2px;
  height: 40px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 18px 10px 38px;
  /* color: #ffffff; */
  font-family: Gm;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #667085;
}

.fileCate {
  width: 169px;
  height: 40px;
  border: 1px solid #d0d5dd;
  background-color: #ffffff;
  outline: none;
}

.viewDoc {
  width: 66px;
  height: 32px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1.33px 2.67px 0px #1018280d;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  color: #344054;
}

.dwnDoc {
  width: 98px;
  height: 32px;
  top: 60px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1.33px 2.67px 0px #1018280d;
  font-family: Gm;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  color: #3e68fe;
}
