.select_dropdown_assign_to {
  border-radius: 5px;
  background: #f4f4f4;
  border: none;
  font-family: Gm;
  font-size: 14px;
  color: #221e1f;
  cursor: pointer;
}

tr:hover .select_dropdown_assign_to {
  background: #ffffff !important;
}

tr:hover .select_dropdown_assign_to:hover {
  background: linear-gradient(
    162.29deg,
    #ffe185 14.78%,
    #f7ba00 95.75%
  ) !important;
  border: none;
  outline: none !important;
}

.select_dropdown_assign_to:focus {
  outline: none !important;
}

tr:hover .select_dropdown_assign_to option:checked {
  background: linear-gradient(
    162.29deg,
    #ffe185 14.78%,
    #f7ba00 95.75%
  ) !important;
  outline: none !important;
}

.meet_cal_show_txt,
.meet_name {
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #151414;
}

.meet_today_btn {
  background: #ffe9b6;
  border-radius: 2px;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  padding: 11px 17px;
}

.meet_arrow_btn {
  background: #f5f5f5;
  border-radius: 2px;
  padding: 7px 10px;
}

.meet_label_date {
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #151414;
}

.meet_select {
  background: #f5f5f5;
  border-radius: 1px;
  border: none;
  cursor: pointer;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #151414;
  padding: 11px 13px;
  outline: none;
}

.total_meeting_btn {
  background: #f7b314;
  padding: 2px 7px;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #151414;
}

.deptSelect {
  width: 193px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  outline: none;
}
